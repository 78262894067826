/* **************** Agent styles ************** */

.agents {
  /* border: 1px solid green; */
  margin-top: 93px;
}

.agents-gu {
  margin-bottom: 80px;
}
/* ************ Phone header *************** */
@media only screen and (max-width: 1150px) {
  .agents {
    /* border: 1px solid green; */
    margin-top: 67px;
  }
}
