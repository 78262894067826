/* ***************** Students Sec 3 Styles *************** */
.student-sec3 {
  /* border: 1px solid red; */
  padding: 80px 0;
}

.ss3-curved-back1 {
  height: 150px;
  /* border: 1px solid red; */
  border-radius: 50% 50% 0 0;
  background: rgba(251, 203, 211, 0.1);
}

.ss3-curved-back2 {
  height: 150px;
  /* border: 1px solid red; */
  border-radius: 0 0 50% 50%;
  background: rgba(251, 203, 211, 0.1);
}

.ss3-main {
  position: relative;
  /* border: 1px solid blue; */
  background: rgba(251, 203, 211, 0.1);
}

.ss3-doted1 {
  position: absolute;
  right: 0;
  bottom: -75px;
  transform: rotate(180deg);
}

.ss3-doted {
  position: absolute;
  left: 0;
  top: -75px;
}

.ss3-left {
  /* border: 1px solid green; */
  width: 34.7%;
}

.ss3-left img {
  width: 100%;
  object-fit: contain;
}

.ss3-right {
  /* border: 1px solid red; */
  width: 35%;
}

.ss3r-body {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 133% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;

  color: rgba(22, 57, 103, 0.9);
  margin-bottom: 30px;
}

@media only screen and (max-width: 850px) {
  .ss3-left {
    width: 70%;
  }

  .ss3-right {
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .ss3-left {
    width: 100%;
  }

  .ss3-right {
    width: 100%;
  }
  .ss3r-body {
    font-size: 18px;
    line-height: 24px;
  }
}
