/* ***************** home part 2 Styles *************** */
/* larger screens */
@media only screen and (min-width: 1742px) {
  .hp-sec1 {
    /* border: 1px solid red; */
    left: 50% !important;
    margin-left: -720px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .margin-p2 {
    /* border: 1px solid green; */
    padding: 0 30px;
    max-width: 1440px;
    margin: 0 auto !important;
  }

  .hp-sec1 {
    max-width: 1440px;
    margin: auto;
    display: block;
    /* border: 1px solid red; */
    /* left: 50% !important; */
    /* margin-left: -720px !important; */
  }
}

.home-part-two {
  /* border: 1px solid green; */
  margin-bottom: 80px;
}

.blue-back {
  background: rgba(202, 238, 252, 0.99);
  height: 155px;
}

.picture-back {
  position: relative;
  /* border: 1px solid blue; */
  background: url("../../images/picture-back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 470px;
  width: 100%;
}

.black-overlay {
  width: 100%;
  height: 100%;
  background: rgba(48, 43, 43, 0.75);
  border: 1px solid transparent;
  position: relative;
}

.margin-p2 {
  /* border: 1px solid yellow; */

  gap: 4.78%;
  margin: 0 6.94%;
}

.hp-sec1 {
  /* border: 1px solid green; */
  display: block;
  width: 83.33%;
  /* margin: 0px auto 0; */
  left: 50%;
  margin-left: -41.5%;
  background-color: white;
  padding: 48px 0px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 0px 24px;
  position: absolute;
  top: -70px;
}

.hp-sec1-word {
  /* border: 1px solid green; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  /* or 125% */

  text-align: center;
  width: 79.6%;

  color: #163967;
  display: block;

  margin: auto;
}

.hp-sec1-word i {
  color: #ef3d5b;
  font-style: normal;
}

.hp-sec2 {
  /* border: 1px solid red; */
  margin: 120px 0 0;
}

.hp-sec2-word {
  font-weight: 600;
  font-size: 32px;
  line-height: 49px;
  /* identical to box height */

  color: #ffffff;
}

.hp-sec2-small {
  margin: 10px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
}

.hp-sec3 {
  /* border: 1px solid yellow; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 48px 0 0;
}

.hp-box-comp {
  /* border: 1px solid blue; */
}

.hpb-numb {
  font-weight: 600;
  font-size: 32px;
  line-height: 59px;
  /* identical to box height */

  color: #ffdada;
}

.hpb-word {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: white;
  /* margin: 24px 0 0; */
  /* identical to box height */
}

.bluenthings {
  background: rgba(202, 238, 252, 0.99);
  padding: 70px 0;
}

.blue-words {
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  /* identical to box height, or 117% */
  margin: 0 0 24px;
  color: #093c80;
}

@media only screen and (max-width: 850px) {
  .margin-p2 {
    gap: 30px;
  }
  .hp-sec1-word {
    width: 90.6%;
  }
}

@media only screen and (max-width: 650px) {
  .margin-p2 {
    margin: 0 24px;
  }
  .hp-sec1-word {
    font-size: 20px;
    line-height: 35px;
  }

  .hp-sec1 {
    width: 83.33%;
    /* margin: 0px auto 0; */

    padding: 30px 0px;

    top: -100px;
  }

  .hp-sec2-word {
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    color: #ffffff;
  }

  .hp-sec2-small {
    margin: 10px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    color: #ffffff;
  }

  .hp-sec3 {
    /* border: 1px solid yellow; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 0;
    row-gap: 20px;
    column-gap: 10px;
    flex-wrap: wrap;
  }

  .hpb-numb {
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    color: #ffdada;
  }

  .hpb-word {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: white;
    /* margin: 24px 0 0; */
    /* identical to box height */
  }

  .blue-words {
    /* border: 1px solid red; */
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 117% */
    margin: 0 0 24px;
    color: #093c80;
  }
}

@media only screen and (max-width: 311px) {
  .picture-back {
    height: 520px;
  }
}
