/* ***************** Contact Us Styles *************** */
.contact-us {
  border: 1px solid transparent;
  margin: 94px 0 80px;
}

.contact-img {
  /* border: 1px solid red; */
  margin-top: 100px;
  height: 500px;
}

.contact-img img {
  height: 100%;
  object-fit: cover;
}

.contact-head {
  /* margin-top: 80px; */
  /* border: 1px solid blue; */
  width: 60%;
  display: block;
  margin: 80px auto 0;
}

.contact-header {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #163967;
  margin-bottom: 18px;
}

.contact-body {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #163967;
}

.contact-main {
  margin-top: 80px;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  /* border: 1px solid brown; */
}

.lets-chat {
  /* border: 1px solid black; */
}

.lets-chat-p {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ef3d5b;
  margin-bottom: 24px;
}

.lets-chat-img {
  width: 200px;
  transition: transform;
  transform: scale(1);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.lets-chat-img:hover {
  transform: scale(0.5);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.lets-chat-img img {
  width: 100%;
}

.contact-left {
  /* border: 1px solid yellow; */
  width: 20.59%;
}

.contact-right {
  /* border: 1px solid blue; */
  width: 49.44%;
}

.contact-form {
  /* border: 1px solid blue; */
  width: 100%;
}

.cf-name {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 24px;
}

.contact-text-area {
  width: 100%;
  margin-bottom: 40px;
}

.contact-text-area textarea {
  border: none;
  border: 1px solid rgba(94, 94, 94, 0.39);
  border-radius: 4px;
  height: 300px;
  padding: 8px 16px;
  width: 100%;
  /* max-width: 100%; */
  background-color: white;
}

.contact-text-area label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #093c80;
}

@media only screen and (max-width: 1150px) {
  .contact-us {
    border: 1px solid transparent;
    margin: 67px 0 80px;
  }
}

@media only screen and (max-width: 850px) {
  .contact-main {
    /* border: 1px solid green; */
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }

  .contact-left {
    /* border: 1px solid yellow; */
    width: 20.59%;
  }

  .contact-right {
    /* border: 1px solid blue; */
    width: 80.44%;
  }

  .contact-img {
    /* border: 1px solid red; */
    /* margin-top: 100px; */
    width: 90%;
    display: block;
    margin: 50px auto 0;
    height: auto;
    /* height: 500px; */
  }

  .contact-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 650px) {
  .contact-right {
    /* border: 1px solid blue; */
    width: 100%;
  }

  .contact-left {
    /* border: 1px solid yellow; */
    width: 20.59%;
    min-width: 100px;
  }
  .lets-chat-img {
    width: 100%;
  }

  .lets-chat-img img {
    width: 100%;
  }

  .contact-head {
    /* margin-top: 80px; */
    /* border: 1px solid blue; */
    width: 100%;
    display: block;
    margin: 80px auto 0;
  }

  .contact-header {
    font-size: 30px;
    line-height: 48px;
  }

  .contact-body {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #163967;
  }

  .lets-chat-p {
    font-size: 30px;
    line-height: 38px;
  }
}
