/* ***************** list of schools Styles *************** */
.list-of-schools {
  /* border: 1px solid red; */
  margin-top: 94px;
  padding: 80px 0px;
  background-color: #f5f5f5;
}

.list-margin {
  display: flex;
  /* border: 1px solid green; */
  gap: 50px;
}

.list-left {
  /* border: 1px solid red; */
  width: 40%;
}

.list-left-white {
  /* border: 1px solid blue; */
  background-color: white;
  height: 500px;
}

.list-right {
  width: 60%;
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 45px;
}

.list-school-component {
}

.lsc-top {
  /* border: 1px solid yellow; */
}

.lsc-top img {
  width: 100%;
  object-fit: cover;
}

.lsc-bottom {
}

.lsc-b-p {
  margin: 10px 0 5px;
  font-weight: 500;
  font-size: 12.5774px;
  line-height: 15px;
  /* or 120% */

  display: flex;
  align-items: center;
  text-align: left;
  color: #ef3d5b;
}

.lsc-b-bottom {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* border: 1px solid green; */
}

.lsc-bb-p {
  /* border: 1px solid yellow; */
  font-weight: 400;
  font-size: 13.1343px;
  line-height: 20px;
  text-align: left;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #163967;
}

.lsc-bb-logo {
  /* border: 1px solid red; */
}

@media only screen and (max-width: 1150px) {
  .list-of-schools {
    /* border: 1px solid green; */
    margin-top: 67px;
  }

  .list-margin {
    flex-direction: column;
  }

  .list-right {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .list-right {
    gap: 30px;
  }
}
@media only screen and (max-width: 650px) {
  .list-right {
    grid-template-columns: auto auto;
    gap: 20px;
  }
}

@media only screen and (max-width: 430px) {
  .lsc-b-bottom {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* border: 1px solid green; */
  }
  .list-right {
    grid-template-columns: auto;
    gap: 20px;
  }
}
