/* ***************** home part 3 Styles *************** */
/* ***************** home part 3 Styles *************** */
.home-part-3 {
  /* border: 1px solid yellow; */
  margin: 0px 0px 80px;
}

.hp3-left {
  /* border: 1px solid red; */
}

.hp3-right {
  /* border: 1px solid pink; */
}

.hp3r-head {
  /* border: 1px solid blue; */
  background: #093c80;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 24px; */
}

.hp3r-word {
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  /* identical to box height, or 150% */

  color: #ffffff;
}

.hp3r-button {
  background: #f5f5f5;
  border-radius: 16px;
  color: #093c80;
  border: none;
  padding: 13px 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 150% */

  color: #093c80;
  cursor: pointer;
}

.hp3r-blog-comp {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 22px 30px;
}

.hp3r-b-left {
  /* border: 1px solid green; */
  overflow: hidden;
}

.hp3r-b-left img {
  /* border: 1px solid black; */
  transition: transform 0.4s ease-in-out;
}

.hp3r-b-left img:hover {
  transform: scale(1.25);
}

.hp3r-b-left img {
  width: 100%;
}

.hp3r-b-right {
  /* border: 1px solid pink; */
  padding-right: 30px;
}

.hbr-head {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  /* identical to box height, or 150% */
  text-align: left;
  color: #ef3d5b;
}

.hbr-body {
  /* border: 1px solid red; */
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 140% */
  text-align: left;

  color: #37474f;
}

.hbr-read {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 140% */
  text-align: left;
  font-style: normal;
  color: #093c80;
  margin-left: 5px;
}

.hbr-date {
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  text-align: left;
  /* identical to box height, or 175% */

  letter-spacing: -0.02em;

  color: #b8b8b8;
}

.hbr-blue-bar {
  background: #093c80;
  height: 1px;
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .hp3r-blog-comp {
    padding: 10px 10px;
  }

  .hp3r-b-right {
    /* border: 1px solid pink; */
    padding-right: 0px;
  }
}

@media only screen and (max-width: 650px) {
  .hp3r-word {
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    /* identical to box height, or 150% */

    color: #ffffff;
  }

  .hp3r-button {
    padding: 13px 20px;

    font-size: 16px;
  }

  .hp3r-b-left {
    /* border: 1px solid red; */
  }
  .hp3r-b-right {
    /* border: 1px solid blue; */
  }

  .hbr-head {
    font-size: 20px;
    line-height: 30px;
  }

  .hbr-body {
    font-size: 16px;
    line-height: 24px;
  }

  .hbr-read {
    font-size: 14px;
    line-height: 24px;
  }

  .hbr-date {
    font-size: 11px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 500px) {
  .hp3-left {
    width: 100%;
  }

  .hp3-left img {
    width: 100%;
    object-fit: cover;
  }
  .hp3r-blog-comp {
    flex-direction: column;
  }

  .hp3r-b-left {
    /* border: 1px solid red; */
    width: 100%;
  }

  .hp3r-b-left img {
    width: 100%;
    object-fit: cover;
  }
  .hp3r-b-right {
    /* border: 1px solid blue; */
    width: 100%;
  }
}
