/* ***************** home Styles *************** */
.home-styles {
  /* border: 1px solid green; */
  margin-top: 94px;
}

@media only screen and (min-width: 1800px) {
  .hero-inside {
    width: 793px !important;
    padding: 50px !important;
  }

  .home-hero {
    max-height: 1200px !important;
  }
}

.home-hero {
  position: relative;
  /* border: 1px solid red; */
  overflow: hidden;
  /* height: fit-content; */
  /* padding: 0 50px; */
  max-height: 700px;
}

.hero-comp {
  position: relative;
  width: 100vw;
  min-width: 100vw;
  /* height: 100%; */

  /* border: 1px solid green; */
  height: 100%;
}

.hero-phone {
  /* border: 1px solid red; */
  height: 100%;
}
.hero-phone-main {
  height: 100%;
}

.hero-comp-main {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-in-out;
}

.hero-comp-main:hover {
  transform: scale(1.03);
}

.hero-comp-main img {
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-large {
  /* border: 1px solid red; */
  /* gap: 4.78%; */
  margin: 0 6.94%;
  width: 100%;
  position: relative;
}

.hero-inside {
  /* position: relative; */
  position: absolute;
  top: 80px;
  left: 6.94%;
  background-color: rgba(0, 0, 0, 0.5);
  /* border: 1px solid red; */
  width: 493px;
  /* margin-top: 80px; */
  padding: 24px;
  transition: background-color 0.4s ease-in-out;
}

.hero-phone:hover > .hero-inside {
  background-color: rgba(0, 0, 0, 0.7);
}
.hero-p {
  /* border: 1px solid green; */
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  /* or 120% */

  display: flex;
  align-items: center;
  letter-spacing: -1px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 50px;
  /* padding: 80px 0 30px; */
}
@media only screen and (max-width: 1150px) {
  .home-styles {
    /* border: 1px solid green; */
    margin-top: 67px;
  }
}
@media only screen and (max-width: 850px) {
}
@media only screen and (max-width: 650px) {
  .hero-phone {
    display: flex;
    flex-direction: column-reverse;
  }
  .hero-phone-main {
    /* height: 50%;
    max-height: 50%; */
    /* border: 1px solid green; */
    overflow: hidden;
  }
  .hero-comp-main {
    height: 100%;
    /* max-height: 50%; */
  }
  .hero-large {
    gap: 0;
    margin: auto;
    width: 100%;
  }
  .hero-inside {
    /* border: 1px solid red; */
    width: 100%;
    /* height: 50%; */
    position: relative;
    top: auto;
    left: auto;
    background: rgba(0, 0, 0, 0.7);
    padding: 24px 24px 50px;
    margin: auto;
  }

  .hero-p {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 20px;
    padding: 20px 0 10px;
  }
}
