/* ***************** supported by Styles *************** */
.student-gu {
  padding: 80px 0;
}

.support-by {
  /* border: 1px solid green; */
  padding: 50px 0 50px 0;
  /* background-color: #f5f5f5; */
  /* margin: 0px 0 80px; */
}

.support-head {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #163967;
  margin-bottom: 40px;
}

.support-scroll {
  position: relative;

  /* border: 1px solid red; */
  /* overflow-x: hidden; */
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.support-scroll::-webkit-scrollbar {
  display: none;
}

.frame-container {
  display: flex;
  /* border: 1px solid blue; */
  /* margin: 5px 0; */
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  gap: 50px;
}

.frame-container::-webkit-scrollbar {
  display: none;
}

.sup-btn {
  /* border: 1px solid red; */
  position: absolute;
  top: 20px;
  right: 50px;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.support-main {
  /* border: 1px solid green; */
  display: flex;
  gap: 50px;
  min-width: 3450px;
  align-items: center;
  /* margin: 5px 0; */
  scroll-behavior: smooth;
  animation: swipe 18000ms linear infinite backwards;
}

.support-comp {
  /* border: 1px solid blue; */
  width: 300px;
  min-width: 300px;
  height: 60px;
  scroll-behavior: smooth;
}

.support-comp img {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  object-fit: contain;
}
