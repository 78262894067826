/* ****************** About Us ********************** */
.about-us {
  /* border: 1px solid blue; */
  margin-top: 94px;
}

.About-sec-one {
  /* border: 1px solid green; */
  margin: 80px 0;
}

.ab1-left {
  /* border: 1px solid blue; */
  width: 49.1%;
}

.ab1-left img {
  width: 100%;
  object-fit: cover;
}

.ab1-right {
  /* border: 1px solid red; */
  width: 33.33%;
}

.ab1r-head {
  /* border: 1px solid yellow; */
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  /* or 120% */
  text-align: left;

  letter-spacing: -0.01em;
  margin-bottom: 20px;
}

.ab1rh-red {
  color: #ef3d5b;
}

.ab1rh-blue {
  color: #163967;
}

.ab1r-body {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* or 150% */
  text-align: left;
  color: #495565;
  margin-bottom: 32px;
}

.ab1r-fancy {
  /* border: 1px solid red; */
  /* transform: rotate(180deg); */
  margin-top: 40px;
  width: fit-content;
  margin-left: 30%;
  transition: transform;
  transform: scale(1) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.ab1r-fancy:hover {
  transform: scale(1.5) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

/* **************** A seamless ************* */

.a-seamless {
  background-color: #f0f4fe;
  padding: 100px 0;
  /* margin-top: 80px; */
}

.a-seam-left {
  /* border: 1px solid green; */
  /* margin-bottom: 40px; */
  width: 43.33%;
}

.a-seam-lu {
  /* border: 1px solid green; */
  margin-bottom: 35px;
}

.a-seam-lu-head {
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  /* or 120% */
  text-align: left;
  color: #163967;
  margin-bottom: 20px;
}

.a-seam-lu-body {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* or 150% */
  text-align: left;
  color: #495565;
}

.a-seam-ld {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  justify-content: space-between;
}

.a-seam-ldl {
  width: 22.66%;
}
.a-seam-ldl img {
  width: 100%;
  object-fit: cover;
}

.a-seam-ldr {
  width: 73.5%;
}

.a-seam-ldrh {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  /* or 144% */
  text-align: left;
  color: #163967;
}

.a-seam-ldrb {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* or 150% */
  text-align: left;
  color: #495565;
}

.a-seam-right {
  /* border: 1px solid yellow; */
  width: 39.86%;
}

.a-seam-right img {
  width: 100%;
  object-fit: cover;
}

/* **************** We are dedicated ************* */
.we-are-dedicated {
  border: 1px solid green;
  /* margin-top: 80px; */
  background: #093c80;
  color: white;
  padding: 70px 0 160px;
}

.about-gu {
  margin-bottom: 80px;
}

.wed-header {
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  /* or 120% */

  text-align: center;
  letter-spacing: -0.01em;
  width: 42.9%;
  display: block;
  margin: 0 auto 40px;
}

.wad-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: start;
  gap: 30px;
}

.wed-flex {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;

  /* justify-content: space-between; */
  gap: 26px;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  /* width: fit-content; */
  /* margin-bottom: 50px; */
}

.wedf-left {
  /* border: 1px solid black; */
}

.wl-white-back {
  background: white;
  padding: 12px 0;
  width: 276px;
  border: 0.734043px solid rgba(9, 60, 128, 0.19);
  border-radius: 12px;
}

.wl-images {
  position: relative;
  /* border: 1px solid yellow; */
}

.wl-first {
}

.wl-first img {
  border-radius: 100%;
}

.wl-second {
  padding: 6px;
  border-radius: 100%;
  background: #f0f4fe;
  position: absolute;
  bottom: 15px;
  right: 65px;
}

.wedf-right {
  /* border: 1px solid blue; */
  /* width: 49.65%; */
  padding: 0 15px;
}

.wl-word {
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #093c80;
}

.wedfr-p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* text-align: left; */
  /* or 133% */
}

@media only screen and (max-width: 1026px) {
  .wad-grid {
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    gap: 30px;
  }
}

@media only screen and (max-width: 850px) {
  .about-us {
    /* border: 1px solid blue; */
    margin-top: 67px;
  }
  .ab1-left {
    /* border: 1px solid green; */
    width: 70%;
  }

  .ab1-right {
    /* border: 1px solid green; */
    width: 70%;
  }

  .a-seam-left {
    /* border: 1px solid yellow; */
    width: 70%;
  }

  .a-seam-right {
    /* border: 1px solid blue; */
    width: 70%;
  }

  .wed-flex {
    /* flex-direction: column;

    gap: 20px; */
  }

  .wed-header {
    width: 80%;
  }

  .wedf-left {
    /* border: 1px solid green; */
    /* width: 70%; */
  }

  .wl-white-back {
    display: block;
    margin: auto;
  }

  .wedf-right {
    /* border: 1px solid green; */
    /* width: 70%; */
  }
}

@media only screen and (max-width: 690px) {
  .wad-grid {
    display: grid;
    grid-template-columns: auto;
    align-items: start;
    gap: 30px;
  }
}

@media only screen and (max-width: 650px) {
  .ab1-left {
    width: 100%;
  }

  .ab1-right {
    width: 100%;
  }

  .ab1r-head {
    font-size: 24px;
    line-height: 30px;
  }

  .ab1r-body {
    font-size: 18px;
    line-height: 25px;
  }
  .a-seam-left {
    width: 100%;
  }

  .a-seam-right {
    width: 100%;
  }

  .a-seam-ld {
  }

  .a-seam-lu-head {
    font-size: 24px;
    line-height: 38px;

    margin-bottom: 15px;
  }

  .a-seam-lu-body {
    font-size: 18px;
    line-height: 25px;
  }

  .a-seam-ldrh {
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    /* or 144% */
    text-align: left;
    color: #163967;
  }

  .a-seam-ldrb {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* or 150% */
    text-align: left;
    color: #495565;
  }

  .wed-header {
    font-size: 24px;
    line-height: 38px;
    /* or 120% */

    text-align: center;
    letter-spacing: -0.01em;
    width: 100%;
    display: block;
    margin: 0 auto 40px;
  }

  .wedf-left {
    /* border: 1px solid green; */
    width: 100%;
  }

  .wl-white-back {
    display: block;
    margin: auto;
  }

  .wedf-right {
    /* border: 1px solid green; */
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .a-seam-ld {
    flex-direction: column;
    gap: 10px;
  }

  .a-seam-ldl {
    width: 100%;
    height: 300px;
  }

  .a-seam-ldl img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .a-seam-ldr {
    width: 100%;
  }
  .wl-white-back {
    width: 100%;
  }
}
