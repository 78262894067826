/* ***************** Page Check 2 Styles *************** */
.page-nav-check {
  /* background: url("../../images/agent-back.png"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120px;
  /* padding: 16px 0; */
  /* margin-bottom: 80px; */
  /* border: 1px solid pink; */
}
.pnc-overlay {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  /* padding: 16px 0 0; */
  border: 1px solid transparent;
}
.pnc-head {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #ffffff;
  margin-top: 16px;
}

.pnc-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pnc-word {
  /* border: 1px solid yellow; */
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.pnc-img {
  border: 1px solid transparent;
  height: 17px;
  width: 21px;
}

.pnc-img img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .pnc-group {
    margin-top: 10px;
  }
  .pnc-head {
    /* border: 1px solid red; */
    font-size: 24px;
    line-height: 30px;

    margin-top: 16px;
  }

  .pnc-word {
    /* border: 1px solid yellow; */
    /* margin-top: 10px; */
    font-size: 18px;
    line-height: 30px;
  }
}
