/* ****************** Partnered Schools ********************** */

.partner-schools {
  /* border: 1px solid green; */
  margin-top: 94px;
}
.mark-presence {
  /* border: 1px solid green; */
  margin: 80px 0;
}

.mp-left {
  /* border: 1px solid yellow; */
  width: 38.75%;
}

.mp-curl-image {
  /* border: 1px solid blue; */
  width: fit-content;
  margin-left: 20%;
  margin-bottom: 30px;
  transition: transform;
  transform: scale(1);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.mp-curl-image:hover {
  transform: scale(1.5);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.mp-curl-image1 {
  /* border: 1px solid blue; */
  width: fit-content;
  transform: rotate(180deg);
  margin-left: 20%;
  margin-top: 80px;
  transition: transform;
  transform: scale(1) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.mp-curl-image1:hover {
  transform: scale(1.5) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.mp-header {
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  /* or 120% */

  letter-spacing: -0.01em;
  text-align: left;

  color: #163967;
  margin-bottom: 20px;
}

.mp-body {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* or 150% */
  text-align: left;
  color: #495565;
  margin-bottom: 32px;
}

.mp-right {
  /* border: 1px solid pink; */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 43.75%;
  align-items: center;
}

.mp-r-left {
  /* border: 1px solid green; */
  width: 49%;
}

.mp-r-left img {
  width: 100%;
}

.mp-r-left img {
  width: 100%;
  object-fit: cover;
}

.mp-r-right {
  width: 49%;
  /* border: 1px solid blue; */
  /* height: 100%; */
}

.mp-rr-up {
  width: 100%;
  margin-bottom: 10px;
  /* height: 354px; */
}

.mp-rr-up img {
  width: 100%;
  /* height: 100%; */
  /* object-fit: cover; */
}

.mp-rr-down {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 110px; */
}

.mprr-d-img {
  /* border: 1px solid black; */
  /* height: 100%; */
  width: 32%;
}

.mprr-d-img img {
  width: 100%;
  object-fit: cover;
  /* height: 100%; */
}

/* ************* Partner  **************** */

.partner {
  /* border: 1px solid red; */

  background: #093c80;
  padding: 50px 0;
  /* margin-top: 80px; */
}

.partner-header {
  border: 5px solid #ef3d5b;
  border-style: none none solid none;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  /* identical to box height, or 120% */

  text-align: center;

  color: white;
  margin-bottom: 40px;
}

.partner-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.partner-comp {
  width: 22%;
  position: relative;
}

.partner-hide {
  display: none;
}

.partner-comp:hover > .partner-hide {
  display: block;
  position: absolute;
  bottom: 0;
  border: 1px border pink;
  background-color: white;
  padding: 10px 15px;
}

.ph-head {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #093c80;
  text-align: left;
}

.ph-p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  text-align: left;

  color: #6b6d7e;
}

.ph-read {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-align: left;
  color: #093c80;
  font-style: normal;
}

.partner-comp img {
  width: 100%;
}

/* ************* Partner with Foreign  **************** */

.partner-w-foreign {
  /* border: 1px solid brown; */
  margin: 80px 0;
}

.pwf-header {
  /* border: 1px solid green; */
  padding-top: 50px;
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  /* identical to box height, or 120% */

  text-align: center;

  color: #ebebeb;
  margin-bottom: 50px;
}

.pwf-flex {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  gap: 4%;
  align-items: center;
  flex-wrap: wrap;
}

.pwf-button {
  /* border: 1px solid red; */
  padding-top: 100px;
  width: fit-content;
  display: block;
  margin: auto;
}

/* .pwf-inside {
  border: 1px solid green;
  display: flex;
  gap: 4%;
  align-items: center;
  margin: auto;
  justify-content: center;
 
} */

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translateY(580px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.display-slider2 {
  display: none;
}

.pwf-c-comp {
  width: 30.6%;
}

.pwf-comp {
  display: block;
  /* border: 1px solid green; */
  width: 100%;
  padding: 60px 30px;
  background: #ffffff;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 312px;
  margin-bottom: 50px;
  animation-name: slideInBottom;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.pwf-comp:hover {
  background: #093c80;
}

.pwfc-img {
  /* border: 1px solid red; */
  margin-bottom: 20px;
}

.pwfc-head {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #163967;
  margin-bottom: 8px;
}

.pwfc-word {
  /* border: 1px solid green; */
  /* padding-bottom: 10px; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 175% */

  text-align: center;

  color: #6b6d7e;
}

.pwf-comp:hover > .pwfc-word {
  color: white;
}
.pwf-comp:hover > .EDFF20 {
  color: #edff20 !important;
}

.pwf-comp:hover > .F8DBD1 {
  color: #f8dbd1 !important;
}

.pwf-comp:hover > .F5B5FF {
  color: #f5b5ff !important;
}

.pwf-comp:hover > .c36E973 {
  color: #36e973 !important;
}

.pwf-comp:hover > .c2CE4F0 {
  color: #2ce4f0 !important;
}

@media only screen and (max-width: 1270px) {
  .pwf-flex {
    /* border: 1px solid black; */
    display: flex;
    /* justify-content: space-around; */
    gap: 2%;
    align-items: center;
    flex-wrap: wrap;
  }

  .pwf-c-comp {
    width: 32%;
  }

  .pwf-comp {
    /* border: 1px solid green; */
    width: 100%;
    padding: 60px 10px;
    background: #ffffff;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    height: 350px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 850px) {
  .mp-left {
    /* border: 1px solid brown; */
    width: 70%;
  }

  .mp-right {
    /* border: 1px solid brown; */
    width: 70%;
  }

  .partner-schools {
    /* border: 1px solid green; */
    margin-top: 67px;
  }

  .pwf-flex {
    /* border: 1px solid black; */
    /* display: flex; */
    justify-content: space-between;
    /* gap: 7%; */
    gap: 0;
    /* align-items: center; */
    flex-wrap: no-wrap;
  }

  .pwf-c-comp {
    width: 47.2% !important;
  }

  .pwf-comp {
    /* border: 1px solid green; */
    width: 100% !important;
    /* height: 312px; */
    padding: 60px 20px;
  }

  .pwfc-img {
    /* border: 1px solid red; */
    margin-bottom: 20px;
  }

  .pwfc-head {
    font-size: 24px;
    line-height: 32px;
  }

  .pwfc-word {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 650px) {
  .pwf-header {
    font-size: 24px;
    line-height: 38px;
  }
  .mp-left {
    /* border: 1px solid brown; */
    width: 100%;
  }

  .mp-right {
    /* border: 1px solid brown; */
    width: 100%;
  }

  .mp-header {
    font-size: 24px;
    line-height: 38px;
  }

  .mp-body {
    font-size: 18px;
    line-height: 25px;
  }

  .partner-header {
    padding-bottom: 24px;

    font-size: 24px;
    line-height: 38px;

    margin-bottom: 40px;
  }

  .partner-flex {
    flex-direction: column;
    row-gap: 20px;
  }

  .partner-comp {
    width: 80%;
  }

  .pwf-c-comp {
    width: 100% !important;
  }

  .pwf-comp {
    /* border: 1px solid green; */
    width: 100% !important;
    /* height: 312px; */
    padding: 60px 30px;
  }
}

@media only screen and (max-width: 330px) {
  .pwf-c-comp {
    width: 100% !important;
  }
  .pwf-comp {
    /* border: 1px solid green; */
    width: 100% !important;
    height: auto;
    padding: 60px 30px;
  }
}
