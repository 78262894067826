/* ***************** SignUp Styles *************** */
.signup {
  /* border: 1px solid green; */
  /* padding-top: 94px; */
}

.signup-head {
  /* border: 1px solid red; */
  display: block;
  margin: 80px auto 80px;
  width: 57.98%;
}

.signup-header {
  font-weight: 600;
  font-size: 34px;
  line-height: 49px;
  /* identical to box height */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #163967;
}

.signup-body {
  margin-top: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #163967;
}

.signup-options {
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 9%;
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  margin-bottom: 80px;
}

.signup-comp {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 56px 20px;
  /* width: 25.06%; */
}

.sc-img {
  /* border: 1px solid yellow; */
  width: 100%;

  /* min-width: 100%; */
}

.sc-img img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.sc-header {
  margin: 15px 0 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #093c80;
}

.sc-body {
  /* border: 1px solid red; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  /* margin: 0 10px; */

  color: #163967;
}

.sc-button {
  width: 100%;
  display: block;
  margin: 25px auto 0;
  /* border: 1px solid blue; */
  border: none;
  padding: 14px 45px;
  background: #f8516d;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  /* display: flex; */
  align-items: center;

  color: #ffffff;
  cursor: pointer;
}

.sc-button:hover {
  background-color: #d33650;
}

.signup-already {
  margin-bottom: 80px;
  padding: 73px 0;
  display: flex;
  gap: 45px;
  justify-content: center;
  align-items: center;
  background: rgba(240, 244, 254, 0.28);
  /* border: 1px solid green; */
}

.sa-p {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height, or 125% */

  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  color: #093c80;
}

.sa-button {
  /* display: block; */
  /* margin: auto; */
  border: none;
  border: 1px solid #f8516d;
  border-radius: 12px;
  padding: 10px 54px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */
  /* text-decoration: dotted; */

  display: flex;
  align-items: center;

  color: #f8516d;
  background-color: white;
  cursor: pointer;
}

.sa-button:hover {
  background-color: #ef3d5b;
  border-radius: 12px;
  border: 1px solid #ef3d5b !important;
  /* border: none; */
  padding: 12px 58px;
  color: white;
  cursor: pointer;
}
.sa-btnn {
  text-decoration: none;
}

@media only screen and (max-width: 1100px) {
  .signup-comp {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 56px 20px;
    /* width: 30.06%; */
  }

  .signup-options {
    /* border: 1px solid blue; */
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3%;
    /* display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 80px; */
  }
}

@media only screen and (max-width: 850px) {
  .signup {
    /* border: 1px solid green; */
    /* padding-top: 67px; */
  }

  .signup-options {
    /* border: 1px solid blue; */
    display: grid;
    grid-template-columns: auto auto;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    flex-wrap: wrap; */
    row-gap: 50px;
  }

  .signup-comp {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 56px 20px;
    /* width: 45.06%; */
  }

  .sc-button {
    padding: 14px 20px;
  }
}

@media only screen and (max-width: 650px) {
  .signup-header {
    font-size: 24px;
    line-height: 39px;
  }

  .signup-body {
    margin-top: 10px;

    font-size: 18px;
    line-height: 30px;
  }

  .signup-already {
    margin-bottom: 80px;
    padding: 73px 0;
    display: flex;
    gap: 25px;
    flex-direction: column;
  }

  .sa-p {
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    /* identical to box height, or 125% */

    /* display: flex; */
    /* align-items: center; */
    /* text-align: center; */

    color: #093c80;
  }

  .sa-button {
  }
}

@media only screen and (max-width: 500px) {
  .signup-comp {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 56px 20px;
    /* width: 100%; */
  }

  .signup-options {
    /* border: 1px solid blue; */
    display: grid;
    grid-template-columns: auto;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    flex-wrap: wrap;
    row-gap: 50px; */
  }

  .sc-img {
    /* border: 1px solid yellow; */
    width: 80%;
    /* min-width: 100%; */
    display: block;
    margin: 0 auto 50px;
  }

  .signup-head {
    /* border: 1px solid red; */
    display: block;
    margin: 80px auto 80px;
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .sc-img {
    /* border: 1px solid yellow; */
    width: 95%;
    /* min-width: 100%; */
    display: block;
    margin: 0 auto 50px;
  }
}
