/* ***************** Students Sec 1 Styles *************** */
.students-sec {
  /* border: 1px solid green; */
  padding: 80px 0;
}

.student-left {
  /* border: 1px solid red; */
  width: 38.9%;
}

.student-left img {
  width: 100%;
  object-fit: cover;
}

.student-right {
  /* border: 1px solid blue; */
  width: 37.12%;
}

.curly-image {
  /* border: 1px solid black; */
  width: fit-content;
  margin-left: 29.7%;
  margin-bottom: 25px;
  transition: transform;
  transform: scale(1);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.curly-image:hover {
  transform: scale(1.5);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.curly-image img {
}

.curly-image2 {
  /* border: 1px solid red; */
  width: fit-content;
  margin-left: 29.7%;
  margin-top: 25px;
  transition: transform;
  transform: scale(1);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.curly-image2:hover {
  transform: scale(1.5);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.curly-image2 img {
  transform: rotate(180deg);
}

.student-main {
  /* border: 1px solid blue; */
}

.student-head {
  /* border: 1px solid red; */
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  /* or 120% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;
  letter-spacing: -0.01em;
  margin-bottom: 18px;

  color: #163967;
}

.student-body {
  /* border: 1px solid yellow; */
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 150% */

  /* display: flex; */
  /* align-items: center; */

  text-align: left;
  margin-bottom: 25px;
  color: #495565;
}

@media only screen and (max-width: 850px) {
  .student-left {
    /* border: 1px solid brown; */
    width: 70%;
  }

  .student-right {
    /* border: 1px solid green; */
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .student-left {
    /* border: 1px solid brown; */
    width: 100%;
  }

  .student-right {
    /* border: 1px solid green; */
    width: 100%;
  }

  .student-head {
    font-size: 24px;
    line-height: 28px;
  }

  .student-body {
    font-size: 18px;
    line-height: 28px;
  }
}

/* *************** student sec 2*************** */

.student-sec2 {
  /* border: 1px solid green; */
  padding: 80px 0;
}

.ss2-scroll {
  /* border: 1px solid red; */
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.ss2-scroll::-webkit-scrollbar {
  display: none;
}
@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.stu-frame-container {
  display: flex;
  /* border: 1px solid blue; */
  /* margin: 5px 0; */
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  gap: 50px;
}

.stu-frame-container::-webkit-scrollbar {
  display: none;
}
.ss2-flex {
  /* border: 1px solid green; */
  display: flex;
  /* justify-content: space-around; */
  gap: 50px;
  align-items: center;
  scroll-behavior: smooth;
  animation: swipe 25000ms linear infinite backwards;
  min-width: 1950px;
  padding-bottom: 20px;
}

.student-sec2-main {
  background: #ffffff;
  box-shadow: 4px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 60px 0 0;
  width: 350px;
  min-width: 350px;
  height: 350px;
  position: relative;
  /* border: 1px solid red; */
  cursor: pointer;
}

.student-sec2-main:hover {
  border: 2px solid #ef3d5b;
}

.s-sec2-img {
  /* border: 1px solid green; */
  /* padding-top: 60px; */
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
}

.s-sec2-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s-sec2-step {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  margin-top: 16px;

  color: #163967;
}

.s-sec2-word {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: rgba(22, 57, 103, 0.9);
  margin-top: 16px;
}

.s-sec2-bottom {
  position: absolute;
  background: #163967;
  border-radius: 0px 0px 24px 24px;
  bottom: 0;
  padding: 10px 0;
  width: 100%;
}

.ssb-word {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #ffffff;
}

@media only screen and (max-width: 350px) {
  .student-sec2-main {
    width: 100vw;
    min-width: 100vw;
    height: 350px;
    position: relative;
    /* border: 1px solid red; */
  }
}
