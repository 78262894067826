/* ******************  frequently asked styles ********************** */
.faq {
  /* border: 1px solid red; */
  padding: 80px;
}

.faq-head {
  /* border: 1px solid green; */
  margin-bottom: 20px;
}

.faq-head p {
  font-size: 34px;
}

.faq-main {
  /* border: 1px solid yellow; */
  width: 60%;
  margin-bottom: 30px;
}

.faq-show {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-word {
  font-weight: 600;
  font-size: 20px;
}

.faq-image {
  cursor: pointer;
}

@keyframes example1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.faq-is-image {
  cursor: pointer;
  animation-name: example1;
  animation-duration: 500ms;
  /* animation-delay: 1s; */
  animation-timing-function: ease-in;
}

@keyframes example {
  from {
    height: 0px;
    padding: 0;
    display: none;
  }
  to {
    height: 150px;
    padding: 24px 0;
    /* display: block; */
  }
}

.faq-hidden {
  /* display: none; */
  /* border: 1px solid brown; */
  padding: 24px 24px 0 0;
  animation-name: example;
  animation-duration: 500ms;
  /* animation-delay: 1s; */
  animation-timing-function: ease-in;
  overflow-y: hidden;
  height: 150px;
}

.faq-is-hidden {
  display: none;
}

.faq-hidden p {
  font-size: 16px;
}
