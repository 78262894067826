/* ***************** home section 2 Styles *************** */

.home-sec-two {
  /* border: 1px solid blue; */
  margin: 0 0 80px;
}

.home-sec-two-flex {
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  gap: 4.78%;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(500px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.hs2-left {
  /* border: 1px solid red; */
  width: 34.7%;

  /* animation-delay: 1s; */
}

.hs2-left1 {
  /* border: 1px solid red; */
  width: 30.08%;
}

.display-slide {
  display: none;
}

.hs2-left-img {
  /* border: 1px solid pink; */
  width: 100%;
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  transition: transform 0.4s ease-in-out;
}

.hs2-left-img:hover {
  transform: scale(1.05);
}
.hs2-left-img img {
  width: 100%;
}

.hs2-left-img2 {
  /* border: 1px solid pink; */
  width: 100%;
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  transition: transform 0.4s ease-in-out;
}

.hs2-left-img2:hover {
  transform: scale(1.05);
}
.hs2-left-img2 img {
  width: 100%;
}
.hs2-right {
  /* border: 1px solid blue; */
  width: 35.42%;
}

@media only screen and (max-width: 1150px) {
  .hs2-left,
  .hs2-left1 {
    width: 40%;
    /* border: 1px solid green; */
  }

  .hs2-right {
    width: 40%;
    /* border: 1px solid blue; */
  }
}

@media only screen and (max-width: 850px) {
  .home-sec-two-flex {
    flex-direction: column-reverse;

    gap: 30px;
  }
  .hs2-left,
  .hs2-left1 {
    width: 70%;
    /* border: 1px solid green; */
  }

  .hs2-right {
    width: 70%;
    /* border: 1px solid blue; */
  }
}

@media only screen and (max-width: 650px) {
  .hs2-left,
  .hs2-left1 {
    width: 100%;
    /* border: 1px solid green; */
  }

  .hs2-right {
    width: 100%;
    /* border: 1px solid blue; */
  }
}
