/* ***************** access to wide sec Styles *************** */
.access-to-wide {
  /* border: 1px solid red; */
  padding: 80px 0;
}

.access-scroll {
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.access-scroll::-webkit-scrollbar {
  display: none;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.agents-frame-container {
  display: flex;
  /* border: 1px solid blue; */
  /* margin: 5px 0; */
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  gap: 24px;
}

.agents-frame-container::-webkit-scrollbar {
  display: none;
}

.access-flex {
  padding: 50px 0;
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 24px;
  scroll-behavior: smooth;
  animation: swipe 18000ms linear infinite backwards;
  /* width: 1700px; */
}

.access-comp {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 23px 17px;
  width: 400px;
  min-width: 400px;
  height: 140px;
}

.access-comp:hover {
  border: 2px solid #093c80;
  border-radius: 8px;
  border-bottom: 10px solid #093c80;
}

.access-left {
  /* border: 1px solid red; */
}

.access-right {
  /* border: 1px solid yellow; */
}

.access-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;
  color: #163967;
}

.access-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;
  color: #6b6d7e;
}
