/* ***************** Footer Styles *************** */

/* larger screens */
@media only screen and (min-width: 1440px) {
  .footer-margin {
    /* border: 1px solid green; */
    padding: 0 30px;
    max-width: 1440px;
    margin: 0 auto !important;
  }
}
.footer {
  /* border: 1px solid yellow; */
  background-color: #163967;
  padding: 64px 0;
}

.footer-margin {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  gap: 5.1%;
  margin: 0 6.94%;
}

.left-side {
  /* border: 1px solid yellow; */
  /* width: 50%; */
  width: 41.8%;
  padding: 32px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.left-back {
  /* border: 1px solid red; */
  /* margin: 0 20px; */
  /* width: 90%; */
}

.foot-logo {
  /* border: 1px solid green; */
  width: fit-content;
  margin: auto;
}

.paragraph {
  /* border: 1px solid blue; */
  margin: 16px 0 32px;
  color: #093c80;
}

.follow {
  /* border: 1px solid red; */
}

.p-follow {
  /* border: 1px solid black; */
  /* font-family: "Montserrat"; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  /* identical to box height, or 140% */

  color: #093c80;
}

.follow-back {
  margin-top: 24px;
  /* border: 1px solid black; */
  display: flex;
  gap: 16px;
  /* justify-content: space-between; */
}

.follow-circle {
  border: 1px solid #093c80;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.follow-circle img {
  /* border: 1px solid red; */
  margin-top: 9px;
  /* width: 100%; */
}

.right-side {
  /* border: 1px solid green; */
  width: 100%;
}

.foot-upside {
  /* border: 1px solid red; */
}

.foot-up-first {
  display: flex;
  gap: 11.1%;
}

.foot-up-second {
  display: none;
}
.flex-headers {
  /* border: 1px solid pink; */
}

.head-flex {
  /* font-family: "Montserrat"; */
  /* border: 1px solid yellow; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  margin-bottom: 16px;
  /* identical to box height, or 140% */

  color: #ffffff;
}
.navv-div {
  /* border: 1px solid red; */
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 8px;
}

.navv-foot {
  /* border: 1px solid pink; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  /* identical to box height, or 150% */

  color: #ffffff;
}

.up-part {
}

.down-part {
  margin-top: 40px;
}

/* downside */
.downside {
  /* display: none; */
  margin-top: 55px;
  /* border: 1px solid yellow; */
  /* background-color: #f5f5f5; */

  /* padding: 21px 29px; */
}

.downside p {
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  /* identical to box height, or 175% */

  color: #ffffff;
}

.down-cover {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4.84%;
}

.down-left {
  /* border: 1px solid red; */
  width: 37.9%;
}

.down-left p {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */
  text-align: left;

  color: #163967;
}

.down-right {
  /* border: 1px solid blue; */
  margin: auto;
  display: block;
  width: 48.4%;
  height: 64px;
}

.input-cover {
  /* border: 1px solid red; */
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
}

.input-cover input {
  /* height: 100%; */
  width: 80%;
  border: none;
  background-color: white;
  padding: 18px 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #6b6d7e;
}

input[type="text"]:focus {
  outline: none;
}

@keyframes identifier {
  0% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(10px);
  }
}

.input-button {
  cursor: pointer;
  height: 100%;
  width: 20%;
  background-color: #163967;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-btn-img {
  /* border: 1px solid green; */
  height: fit-content;
  /* width: 20px; */
}

.input-button:hover > .input-btn-img {
  animation-name: identifier;
  animation-timing-function: ease-in-out;
  animation-duration: 500ms;
  animation-delay: 200ms;
}

.input-btn-img img {
  width: 100%;
}

/* .down-right :hover > .input-cover > .input-button > .input-btn-img {
  width: 300px;
  border: solid yellow !important;
  cursor: pointer;
} */

@media only screen and (max-width: 1150px) {
  .footer {
    padding: 0;
  }
  .footer-margin {
    flex-direction: column;
    display: flex;
    margin: 0;
  }

  .left-side {
    /* border: 1px solid red; */
    width: 100%;
    padding: 32px 24px;
  }

  .foot-logo {
    margin: 0;
    /* border: 1px solid green; */
  }

  .paragraph {
    text-align: left;
    /* border: 1px solid red; */
  }

  .follow {
    /* border: 1px solid yellow; */
  }
  .p-follow {
    /* border: 1px solid blue; */
  }

  .follow-back {
    /* border: solid pink; */
  }

  .right-side {
    /* border: 1px solid yellow; */
    padding: 32px 24px;
  }

  .down-right {
    /* border: 1px solid blue; */

    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .foot-up-first {
    /* border: 1px solid yellow; */
    margin-bottom: 30px;
  }

  .follow-circle {
    width: 30px;
    height: 30px;
  }

  .follow-back {
    /* border: solid pink; */
    margin-top: 16px;
  }

  .follow-circle img {
    /* border: 1px solid black; */
    width: 16px;
    height: 16px;
    margin-top: 6px;
  }
  .foot-up-second {
    display: block;
    /* border: 1px solid red; */
  }

  .fh-first {
    display: none;
  }

  .down-right {
    /* border: 1px solid blue; */

    width: 100%;
  }

  .input-cover {
    /* border: 1px solid red; */
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
  }

  .input-cover input {
    /* height: 100%; */
    /* border: 1px solid green; */
    width: 100% !important;
    border: none;
    background-color: white;
    padding: 18px 16px;
    font-weight: 500;

    line-height: 28px;
    /* identical to box height, or 140% */
    font-size: 16px;
    color: #6b6d7e;
  }

  input[type="text"]:focus {
    outline: none;
  }

  .input-button {
    /* border: 1px solid yellow; */
    cursor: pointer;
    height: 100%;
    width: 20%;
    background-color: #163967;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-btn-img {
    /* border: 1px solid green; */
    height: fit-content;
  }
}
