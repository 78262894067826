/* ***************** your goals achievable Styles *************** */
/* larger screens */
@media only screen and (min-width: 1440px) {
  .yga-margin {
    /* border: 1px solid green; */
    padding: 0 30px;
    max-width: 1440px;
    margin: 80px auto 0 !important;
  }
}

.your-goals {
  /* border: 1px solid yellow; */
  margin-bottom: 80px;
}

.yga-left {
  /* border: 1px solid brown; */
  width: 38.82%;
  margin-top: 80px;
}

.yga-margin {
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;
  /* align-items: center; */
  gap: 4.78%;
  margin: 0 6.94%;
}

.yga-head {
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  /* or 120% */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: #163967;
  text-align: left;
  margin-bottom: 12px;
}

.yga-body {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: left;

  color: #495565;
  margin-bottom: 24px;
}

.yga-curly {
  /* border: 1px solid red; */

  width: fit-content;
  margin-left: 28.9%;
  margin-top: 50px;
  transition: transform;
  transform: scale(1) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.yga-curly:hover {
  transform: scale(1.5) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
.yga-right {
  /* border: 1px solid green; */
  width: 44.07%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.yga-r-comp {
  width: 30.8%;
  height: 500px;
  /* border: 1px solid blue; */
  transition: width 0.9s;
  transition-timing-function: ease-in;
  /* transition-delay: 0.5s; */
}

.yga-r-comp:hover {
  width: 350%;
  /* -ms-transform: scale(3, 1.2); 
  -webkit-transform: scale(3, 1.2); 
  transform: scale(3, 1.2); */
}

.yga-r-comp img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

/* ***********  why work with foreign ********* */

.why-foreign {
  /* border: 1px solid red; */
  margin-bottom: 80px;
}

.wf-left {
  /* border: 1px solid green; */
  width: 49.44%;
}

.wf-left img {
  width: 100%;
  object-fit: cover;
}

.wf-right {
  width: 34.4%;
}

@media only screen and (max-width: 850px) {
  .yga-margin {
    /* border: 1px solid green; */
    flex-direction: column-reverse;
    gap: 30px;
    align-items: center;
  }

  .yga-left {
    margin-top: 0px;
  }

  .yga-left,
  .wf-left {
    /* border: 1px solid red; */
    width: 70%;
  }

  .yga-right,
  .wf-right {
    /* border: 1px solid blue; */
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .yga-margin {
    margin: 0 24px;
  }

  .yga-left,
  .wf-left {
    width: 100%;
  }

  .yga-right,
  .wf-right {
    width: 100%;
  }

  .yga-head {
    font-size: 24px;
    line-height: 38px;

    margin-bottom: 12px;
  }

  .yga-body {
    font-size: 18px;
    line-height: 30px;

    margin-bottom: 24px;
  }
}
