/* ***************** main-container Styles *************** */
.m-container-main {
  /* overflow: hidden; */
  /* border: 1px solid pink; */
  /* position: fixed; */
  /* height: 100vh; */
}

.fresh-cont {
  border: 1px solid red;
}
