/* ****************** Influencers ********************** */

.influencers {
  /* border: 1px solid blue; */
  margin-top: 94px;
}
.influ-sec1 {
  /* border: 1px solid black; */
  margin-top: 80px;
}

.influ-left {
  /* border: 1px solid yellow; */
  width: 44.4%;
}

.influ-left img {
  width: 100%;
  object-fit: cover;
}

.influ-right {
  /* border: 1px solid black; */
  width: 34.6%;
}
.influ-header {
  /* border: 1px solid red; */
  /* margin-top: 40px; */
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  /* or 120% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;
  letter-spacing: -0.01em;

  color: #163967;
}

.influ-body {
  /* border: 1px solid red; */
  margin: 50px 0;
}

.influs-body {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 150% */

  /* display: flex;
  align-items: center; */
  text-align: left;

  color: #495565;
  margin-bottom: 32px;
}

.fancy-img-influ-one {
  /* margin-top: 40px; */
  transition: transform;
  transform: scale(1) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.fancy-img-influ-one:hover {
  transform: scale(1.5) rotate(180deg);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.fancy-img-influ {
  /* margin-top: 40px; */
  transition: transform;
  transform: scale(1);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.fancy-img-influ:hover {
  transform: scale(1.5);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}
/* ************** Why Partner with foreign *********** */

.why-partner {
  /* border: 1px solid red; */
  margin-top: 100px;
}

.whyp-left {
  /* border: 1px solid blue; */
  width: 39.02%;
}

.whypl-header {
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  /* or 120% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;

  color: #ef3d5b;
  margin-bottom: 40px;
}

.whypl-comp {
  /* border: 1px solid black; */
  /* border-radius: 12px 12px 0 0; */
  margin-bottom: 40px;
}

.whyplc-visible {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #093c80;
  padding: 16px 24px;
  border-radius: 12px;
  cursor: pointer;
}

.wv-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wv-right {
  /* border: 1px solid yellow; */
}

.wv-right1 {
  transform: rotate(180deg);
}

.wvl-img {
}

.wvl-p {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.whyplc-hidden {
  border: 1px solid #e0e0e0;
  /* border-radius: 16px 16px 0px 0px; */
  /* border: 1px solid #f5f5f5; */
  border-style: none solid solid solid;
  padding: 27px 29px;
  z-index: 2;
  /* margin-top: -10px; */
  background-color: white;
}

.whyplc-hidden-none {
  display: none;
}

.whyplc-hidden p {
  /* border: 1px solid pink; */
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  color: rgba(22, 57, 103, 0.7);
}

.whyp-right {
  /* border: 1px solid red; */
  width: 44.2%;
}

.whyp-right img {
  width: 100%;
  object-fit: cover;
}

/* ************** Why audience *********** */

.why-audience {
  margin: 120px 0 100px;
  /* border: 1px solid green; */
}

.wa-head {
  /* border: 1px solid brown; */
}

.wah-header {
  /* border: 1px solid yellow; */
  width: 80%;
  /* margin-bottom: 50px; */
  margin: 0 auto 80px;
}

.wah-header p {
  font-weight: 700;
  font-size: 34px;
  line-height: 47px;
  /* identical to box height */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;

  color: #133058;
}

.wah-circle {
  padding: 8px 32px;
  background: #caeefc;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  width: 68%;
  /* display: block; */
  margin: 0 auto 80px;
  gap: 10px;
}

.wah-circle p {
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;

  color: #163967;
}

.why-a-left {
  /* border: 1px solid red; */
  width: 25%;
}

.wal-head {
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  /* identical to box height, or 125% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;

  color: #163967;
  margin-bottom: 20px;
}

.wal-body {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* or 143% */

  /* display: flex; */
  /* align-items: center; */
  text-align: left;

  color: #163967;
  margin-bottom: 40px;
}

.wal-sign-up {
  padding: 12px 24px;
  border: 1.5px solid #093c80;
  border-radius: 12px;
  width: 90%;
  cursor: pointer;
  background-color: #093c80;
}

.wal-sign-up-nav {
  cursor: pointer;
  text-decoration: none;
}

.wal-sign-up p {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  /* display: flex; */
  align-items: center;

  color: white;
}

.why-a-right {
  /* border: 1px solid yellow; */
  width: 55.9%;
}
.why-a-right img {
  width: 100%;
}

@media only screen and (max-width: 1150px) {
  .influencers {
    /* border: 1px solid blue; */
    margin-top: 67px;
  }
}
@media only screen and (max-width: 850px) {
  .influ-left,
  .whyp-left,
  .why-a-left {
    /* border: 1px solid green; */
    width: 70%;
  }

  .influ-right,
  .whyp-right,
  .why-a-right {
    /* border: 1px solid red; */
    width: 70%;
  }

  .wal-sign-up {
    width: 100%;
    cursor: pointer;
  }

  .wah-circle {
    width: 80%;
    gap: 10px;
  }

  .wah-circle p {
    font-size: 18px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 650px) {
  .influ-left,
  .whyp-left,
  .why-a-left {
    /* border: 1px solid green; */
    width: 100%;
  }

  .influ-right,
  .whyp-right,
  .why-a-right {
    /* border: 1px solid red; */
    width: 100%;
  }

  .whypl-header {
    font-size: 24px;
    line-height: 38px;
  }

  .wvl-p {
    font-size: 18px;
    line-height: 30px;
  }

  .wvl-img {
    /* border: 1px solid red; */
    width: 35px;
    height: 35px;
  }

  .wvl-img img {
    width: 100%;
  }

  .wal-head {
    font-size: 24px;
    line-height: 35px;
  }

  .wal-body {
    font-size: 18px;
    line-height: 24px;
  }

  .wal-sign-up p {
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    /* display: flex; */
    align-items: center;

    color: #163967;
  }

  .wah-circle {
    flex-direction: column;
    width: 90%;
    gap: 10px;
    margin: 0 auto 50px;
    align-items: center;
  }

  .wah-header {
    /* border: 1px solid yellow; */
    width: 90%;
    /* margin-bottom: 50px; */
    margin: 0 auto 50px;
  }

  .wah-header p {
    font-size: 24px;
    line-height: 37px;
  }

  .influ-header {
    font-size: 24px;
    line-height: 38px;
  }

  .influs-body {
    font-size: 18px;
    line-height: 28px;
  }
}
