/* ***************** join global network Styles *************** */
.join-network {
  /* border: 1px solid green; */
  padding: 80px 0px 0;
}

.join-img1 {
  position: absolute;
  top: -75px;
  right: 0;
}

.join-img2 {
  position: absolute;
  transform: rotate(180deg);
  bottom: -75px;
  left: 0;
}

.join-main {
  position: relative;
  /* border: 1px solid yellow; */
  background-color: #093c80;
  padding: 50px 0;
}

.join-left {
  /* border: 1px solid brown; */
  width: 40.9%;
}

.join-head {
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  /* or 120% */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: white;
  text-align: left;
  margin-bottom: 12px;
}

.join-body {
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: left;

  color: white;
  margin-bottom: 24px;
}

.join-button {
  cursor: pointer;
  background: #ffffff;
  border: 0.7px solid #ffffff;
  border-radius: 8.98113px;
  padding: 12px 45px;
  font-weight: 600;
  font-size: 22.4528px;
  line-height: 26px;
  display: flex;
  align-items: center;
  border: none;
  color: #163967;
}

.join-right {
  /* border: 1px solid red; */
  width: 35%;
}

.join-right img {
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .join-left {
    /* border: 1px solid green; */
    width: 70%;
  }

  .join-right {
    /* border: 1px solid red; */
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .join-left {
    width: 100%;
  }

  .join-right {
    width: 100%;
  }

  .join-head {
    font-size: 24px;
    line-height: 38px;
  }

  .join-body {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
  }

  .join-button {
    font-size: 18px;
  }
}
