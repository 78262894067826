/* ***************** login Styles *************** */
.login {
  /* border: 1px solid blue; */
  display: flex;
  /* align-items: center; */
  /* height: 100vh; */
  /* margin-bottom: 600px; */
  /* margin-top: 94px; */
  /* padding: 0 0 50px; */
}

.login-left {
  /* border: 1px solid yellow; */
  width: 41.59%;
}

.login-logo {
  /* border: 1px solid pink; */
  /* margin-top: 40px; */
  width: fit-content;
  display: block;
  margin: 40px auto 24px;
}

.login-right {
  /* border: 1px solid green; */
  width: 58.4%;
  /* height: 100vh; */
}

.or-line {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-top: 40px; */
  width: 60%;
  margin: 40px auto 0;
}

.or-p {
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;

  color: #093c80;

  transform: rotate(0.57deg);
}
.line {
  background-color: #d9d9d9;
  height: 1px;
  width: 100%;
}

.login-right img {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
}

/* login part */

form {
  /* border: 1px solid black; */
  width: 60%;
  display: block;
  margin: auto;
}

.login-head {
  /* border: 1px solid red; */
}

.login-head p {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  color: #093c80;
  margin-bottom: 24px;
}

.login-input {
  /* border: 1px solid blue; */
  width: 100%;
  margin-bottom: 15px;
}

.login-input label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #093c80;
}

.login-input input {
  border: none;
  border: 1px solid rgba(94, 94, 94, 0.39);
  border-radius: 4px;
  height: 50px;
  padding: 8px 16px;
  width: 100%;
  /* max-width: 100%; */
  background-color: white;
}

.remember {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.remember input {
  /* border: 1px solid red; */
}

.remember label {
  /* border: 1px solid blue; */
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  color: #777777;
}

.login-btn2 {
  border: none;
  background: #f8516d;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.login-btn2:hover {
  background-color: #d33650;
}

.login-btn2 p {
  /* border: 1px solid yellow; */
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */
  /* text-align: center; */
  color: #ffffff;

  margin: auto;
  padding: 14px 30px;
}

/********** login other ***********/

.login-other {
  /* border: 1px solid black; */
  width: 60%;
  margin: 40px auto 0;
}

.login-with {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;
  cursor: pointer;
}

.login-with:hover {
  border: 1px solid #093c80;
}

.l-with-img {
  /* border: 1px solid black; */
}
.l-with-p {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #ef3d5b;
}

.forgot {
  /* border: 1px solid yellow; */
  margin: 16px 0 40px;
  cursor: pointer;
}

.forgot p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #777777;
}

.no-account {
  /* border: 1px solid red; */
}

.dont-have {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  display: flex;
  align-items: center;

  color: #777777;
  margin-bottom: 16px;
}

.register {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #093c80;
  cursor: pointer;
}

.privacy-cookies {
  margin-top: 80px;
  /* border: 1px solid blue; */
}

.privacy {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #0baaec;
  margin-bottom: 16px;
  cursor: pointer;
}

.terms {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #0baaec;
  cursor: pointer;
}

@media only screen and (max-width: 1150px) {
  .login-left {
    /* border: 1px solid yellow; */
    width: 41.59%;
  }

  .login-right {
    /* border: 1px solid green; */
    width: 58.4%;
    /* height: 100vh;
     */
  }

  form {
    /* border: 1px solid blue; */
    width: 80%;
  }

  .or-line {
    width: 80%;
  }

  .login-other {
    /* border: 1px solid black; */
    width: 80%;
    margin: 40px auto 0;
  }
}

@media only screen and (max-width: 850px) {
  .login {
    /* margin-top: 67px; */
    flex-direction: column;
    padding: 0 0 50px;
  }

  .login-left {
    width: 100%;
  }

  .login-right {
    display: none;
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .login-left {
    padding: 0 24px 50px;
  }

  form {
    width: 100%;
  }

  .or-line {
    width: 100%;
  }

  .login-other {
    width: 100%;
  }
}
