/* **************** What are clients saying ************* */
.what-are-clients {
  /* border: 1px solid green; */
  margin: 80px 0;
}

.wac-left {
  width: 42.98%;
}

.wac-left img {
  width: 100%;
  object-fit: cover;
}

.wac-right {
  /* border: 1px solid blue; */
  width: 35.69%;
}

.wac-r-up {
  /* border: 1px solid red; */
  margin-bottom: 40px;
}

.wac-r-up-head {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
  /* identical to box height, or 120% */

  color: #163967;
  text-align: left;
  margin-bottom: 10px;
}

.wac-r-up-body {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 133% */

  color: #495565;
  text-align: left;
}

.wac-r-down {
  /* border: 1px solid blue; */
}

.wac-rd-up {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* or 150% */

  color: #163967;
  background: #f0f4fe;
  border-radius: 16px 16px 0px 0px;
  padding: 22px 30px;
  text-align: left;
  border-bottom: 1px solid #ccdde2;
}

.wac-rd-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f4fe;
  border-radius: 0px 0px 16px 16px;
  padding: 22px 30px;
  gap: 10px;
}

.wacrd-d-left {
  /* border: 1px solid green; */
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.wacrdd-l-left {
}

.wacrdd-l-left img {
  border-radius: 100%;
}

.wacrdd-l-right {
}

.wacrdd-lr-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #163967;
}

.wacrdd-lr-p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #163967;
}

.star-cont {
  display: flex;
  gap: 10px;
  align-items: center;
}

.one-star {
  transition: transform 0.2s ease-in-out;
}

.one-star:hover {
  transform: translateY(-10px);
}

@media only screen and (max-width: 1350px) {
  .wac-right {
    /* border: 1px solid blue; */
    width: 45%;
  }
}

@media only screen and (max-width: 1050px) {
  .wac-right {
    /* border: 1px solid blue; */
    width: 50%;
  }
}

@media only screen and (max-width: 850px) {
  .wac-left {
    width: 70%;
  }

  .wac-right {
    /* border: 1px solid blue; */
    width: 70%;
  }
}

@media only screen and (max-width: 650px) {
  .wac-left {
    width: 100%;
  }

  .wac-right {
    /* border: 1px solid blue; */
    width: 100%;
  }

  .wac-r-up-head {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 120% */

    color: #163967;
    text-align: left;
    margin-bottom: 10px;
  }

  .wac-r-up-body {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 133% */

    color: #495565;
    text-align: left;
  }

  .wac-rd-up {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 150% */

    color: #163967;
    background: #f0f4fe;
    border-radius: 16px 16px 0px 0px;
    padding: 22px 10px !important;
    text-align: left;
    border-bottom: 1px solid #ccdde2;
  }

  .wacrdd-lr-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;

    color: #163967;
  }

  .wacrdd-lr-p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    color: #163967;
  }
}

@media only screen and (max-width: 460px) {
  .wac-rd-down {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}

@media only screen and (max-width: 330px) {
  .wac-rd-up {
    border-radius: 16px 16px 0px 0px;
    padding: 22px 10px !important;
    text-align: left;
    border-bottom: 1px solid #ccdde2;
  }

  .wac-rd-down {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 22px 10px !important;
  }
}
