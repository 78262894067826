/* ***************** Header Styles *************** */

/* larger screens */
@media only screen and (min-width: 1440px) {
  .margin-head {
    /* border: 1px solid green; */
    padding: 0 30px;
    max-width: 1440px;
    margin: 0 auto !important;
  }
}
.header {
  /* border: 1px solid red; */
  padding: 4px 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 5;
  max-height: 94px;
}
.logo-comp {
  /* border: 1px solid red; */
  width: 100px;
  height: 85px;
}
.logo-comp img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.margin-head {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  margin: 0 6.94%;
  align-items: center;
}

.navigation {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  /* margin: 0 30px; */
}

.nav {
  /* border: 1px solid green; */
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 120% */

  color: #163967;
  text-decoration: none;
  cursor: pointer;
  /* width: 100px; */
}

.nav-stroke:after,
.nav-stroke:before {
  transition: all 0.5s;
}

.nav-stroke:hover {
  color: #555;
}

.nav-stroke {
  position: relative;
}

.nav-stroke:after {
  position: absolute;
  bottom: -2px;

  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #ef3d5b;
  height: 2px;
}

.nav-stroke:hover:after {
  width: 100%;
}

.nav-current {
  border-bottom: 2px solid #163967;
  padding-bottom: 2px;
}

.nav-cur-mobile {
}

.login-btn {
  display: flex;
  align-items: center;
  /* border: 1px solid yellow; */
  cursor: pointer;
}

.login-btn .nav button:hover {
  background-color: #ef3d5b;
  border-radius: 12px;
  border: 1px solid #ef3d5b !important;
  /* border: none; */
  padding: 12px 58px;
  color: white;
  cursor: pointer;
}

.login-btn .nav button {
  background-color: white;
  border-radius: 12px;
  border: none;
  border: 1px solid #ef3d5b;
  padding: 12px 58px;
  color: #ef3d5b;
  cursor: pointer;
}

.phone-header {
  display: none;
}

/* ************ Phone header *************** */
@media only screen and (max-width: 1150px) {
  .header {
    display: none;
  }
  .phone-header {
    /* border: 1px solid yellow; */
    display: block;
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
    /* margin-top: 84.6px; */
    /* height: 100vh; */
  }

  .main-phone-header {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    background-color: white;
  }

  .phone-logo {
    /* border: 1px solid red; */
    width: 100px;
    height: 65px;
  }
  .phone-logo img {
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hamburger {
    /* width: 40px;
  height: 40px;
  border: 1px solid green; */
  }

  .hidden-nav {
    /* border: 1px solid blue; */
    /* display: none; */
    background-color: white;
    width: 50%;
    padding: 0 24px;
    height: 100vh;
  }

  .display-none {
    display: none;
  }

  .h-nav-comp {
    text-decoration: none;
    /* border: 1px solid pink; */
    border: 1px solid #d9d9d9;
    border-style: none none solid none;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    padding: 16px 0;
    color: #163967;
  }
}

@media only screen and (max-width: 650px) {
  .phone-logo {
    /* border: 1px solid red; */
    width: 200px;
  }

  .hidden-nav {
    /* border: 1px solid blue; */

    width: 70%;
  }
}
