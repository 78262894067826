/* ***************** Page Check 2 Styles *************** */
.how-we-work {
  /* border: 1px solid red; */
  margin: 80px 0 0;
}

.hww-header {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height, or 121% */

  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  letter-spacing: -0.01em;

  color: #163967;
}

.image-horizon {
  /* border: 1px solid green; */
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.image-horizon::-webkit-scrollbar {
  display: none;
}

.main-horizon {
  /* border: 1px solid yellow; */
}

/* @keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
} */

.hww-frame-container {
  display: flex;
  /* border: 1px solid blue; */
  /* margin: 5px 0; */
  padding: 50px 0 50px 50px;

  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  gap: 24px;
}

.hww-frame-container::-webkit-scrollbar {
  display: none;
}
.images-main-h {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  gap: 24px;
  width: fit-content;
  /* margin-right: 50px; */
  margin-bottom: 20px;
  scroll-behavior: smooth;
  animation: swipe 30000ms linear infinite backwards;
}

.image-b-comp {
  background: #ffffff;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 350px;
  min-width: 350px;
  height: 280px;
  position: relative;
}

.top-back {
  background: #f8dbd1;
  border-radius: 8px 8px 0 0;
  height: 10px;
  transition: height;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  position: absolute;
  top: 0px;
  width: 100%;
}

.image-b-comp:hover > .top-back {
  height: 100%;
  border-radius: 8px;
  /* z-index: -1; */
}

.image-word-main {
  /* border: 1px solid red; */
  padding: 27px;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.image-h-icon {
  /* border: 1px solid brown; */
  margin-bottom: 10px;
}

.image-h-icon img {
  /* border: 1px solid yellow; */
}

.image-h-step {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  /* width: fit-content; */
  /* border: 1px solid blue; */
  color: #163967;
  margin-bottom: 10px;
}

.image-h-head {
  /* border: 1px solid brown; */
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* display: flex; */
  /* align-items: center; */
  text-align: center;
  /* width: fit-content; */
  color: #163967;
  margin: 5px;
}

.image-h-word {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 175% */

  text-align: center;

  color: #6b6d7e;
}

.dots-group {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.one-dot-current {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: #093c80;
}

.one-dot {
  height: 14px;
  width: 14px;
  border: 2px solid #093c80;
  border-radius: 100%;
}
