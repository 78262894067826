/* ****************** student styles ********************* */
.students {
  /* border: 1px solid black; */
  margin-top: 93px;
}

/* ************ Phone header *************** */
@media only screen and (max-width: 1150px) {
  .students {
    margin-top: 67px;
  }
}
