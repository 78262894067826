/* ***************** home section 1 Styles *************** */

/* larger screens */
@media only screen and (min-width: 1440px) {
  .margin-p {
    /* border: 1px solid green; */
    padding: 0 30px;
    max-width: 1440px;
    margin: 0 auto !important;
  }
}

.home-sec-one {
  /* border: 1px solid green; */
  margin: 80px 0 80px;
}
.margin-p {
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  gap: 4.78%;
  margin: 0 6.94%;
}

.hs1-left {
  /* border: 1px solid blue; */

  width: 35.42%;
}

.hs1-left-img {
  /* border: 1px solid green; */
  margin-bottom: 50px;
  width: fit-content;
  margin-left: 14.1%;
  transition: transform;
  transform: scale(1);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.hs1-left-img:hover {
  transform: scale(1.5);
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.hs1-left-img img {
}

.hs1-left-div {
  /* border: 1px solid yellow; */
}

.hs1-head {
  /* border: 1px solid red; */
  font-weight: 600;
  font-size: 36px;
  line-height: 38px;
  /* or 120% */

  color: #163967;
  text-align: left;
}

.hs1-body {
  margin: 16px 0 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  /* or 150% */

  color: #163967;
}

.hs1-button {
  padding: 12.5px 18px;
  border-radius: 12px;
  /* border: 1px solid red; */
  /* width: 190px; */
  width: fit-content;
  /* height: 68px; */
  background-color: #ef3d5b;

  text-decoration: none;
}
.hs1-navlink {
  text-decoration: none;
  cursor: pointer;
}

.hs1-button:hover {
  background-color: #d33650;
}

.hs1-btn-grp {
  /* border: 1px solid yellow; */
  display: flex;
  align-items: center;
  gap: 24px;
  /* justify-content: space-between; */
}
.hs1-button p {
  /* border: 1px solid blue; */
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
}

.hs1-btn-img {
  /* border: 1px solid green; */
  width: 42px;
  height: 42px;
}

.hs1-btn-img img {
  width: 100%;
}

.hs1-right {
  /* border: 1px solid red; */
  width: 39.6%;
  /* cursor: pointer; */
}

.hs1-up {
  /* border: 1px solid blue; */

  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 16px;
}

.hs1-up-left {
  /* border: 1px solid green; */
  width: 56.14%;
  transition: width 200ms ease-in-out;
}

.hs1-up-left img {
  width: 100%;
}

.hs1-up-right {
  /* border: 1px solid red; */
  width: 35.1%;
  padding-top: 120px;
  transition: width 200ms ease-in-out;
}

.hs1-up-right img {
  width: 100%;
}

.hs1-down {
  /* border: 1px solid yellow; */

  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.hs1-down-left {
  /* border: 1px solid brown; */
  width: 17.54%;
  padding: 50px 0 0 40px;
  transition: width 200ms ease-in-out;
}
.hs1-down-left img {
  width: 100%;
}

.hs1-down-right {
  /* border: 1px solid blue; */
  width: 56.14%;
  transition: width 200ms ease-in-out;
}

.hs1-down-right img {
  width: 100%;
}

.hs1-right:hover > .hs1-up > .hs1-up-left {
  /* border: 2px solid yellow; */
  width: 35.1% !important;
  margin-top: 100px;
}

.hs1-right:hover > .hs1-up > .hs1-up-right {
  /* border: 2px solid yellow; */
  width: 56.14% !important;
}

.hs1-right:hover > .hs1-down > .hs1-down-left {
  /* border: 2px solid yellow; */
  width: 56.14% !important;
}

.hs1-right:hover > .hs1-down > .hs1-down-right {
  /* border: 2px solid yellow; */
  width: 35.1% !important;
}

@media only screen and (max-width: 1150px) {
}
@media only screen and (max-width: 850px) {
  .margin-p {
    /* display: flex; */
    flex-direction: column;
    /* border: 1px solid red;
    justify-content: center;
    align-items: center;
    gap: 4.78%;
    margin: 0 6.94%; */
    gap: 30px;
  }

  .hs1-left {
    width: 70%;
  }

  .hs1-right {
    width: 70%;
  }

  .hs1-btn-img {
    /* border: 1px solid green; */
    height: 40px;
    width: 40px;
  }
  .hs1-btn-img img {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .margin-p {
    margin: 0 24px;
  }
  .hs1-left {
    width: 100%;
  }

  .hs1-right {
    width: 100%;
  }
  .hs1-head {
    /* border: 1px solid red; */
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    /* or 120% */

    color: #163967;
    text-align: left;
  }

  .hs1-body {
    margin: 16px 0 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    /* or 150% */

    color: #163967;
  }

  .hs1-button {
    padding: 12.5px 18px;
    border-radius: 4px;
    /* border: 1px solid red; */
    /* width: 190px; */
    width: fit-content;
    /* height: 68px; */
    background-color: #ef3d5b;
    cursor: pointer;
  }

  .hs1-btn-grp {
    /* border: 1px solid yellow; */

    gap: 18px;
  }
  .hs1-button p {
    font-size: 20px;
    line-height: 28px;
  }

  .hs1-btn-img {
    /* border: 1px solid green; */
    height: 30px;
    width: 30px;
  }
  .hs1-btn-img img {
    width: 100%;
  }
}
